import FadeIn from 'react-fade-in';
import { message, Typography, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import axios from 'axios';
import { doc, updateDoc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import ReactGA from "react-ga4";

// Page that handles upgrading of the user's account to premium
function PaymentSuccessful() {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

  // React Router hook to navigate to different pages
  const navigate = useNavigate();

  useEffect (() => {
    document.title = "Payment Successful | Auxify";
    // Gets the current URL in the browser
    const url = window.location.href;
    // Uses RegEx to get the Spotify access token from the current URL
    let accessToken = url.match('(?:access_token=)(.+)(?:&token_type)');
    if (accessToken) {
        accessToken = accessToken[1];
    } else {
        // Redirect the user to Spotify authentication if they're coming from the Stripe payment link
        redirectSpotifyAuth();
    }

    const getUserDataAndContinue = async () => {
        // Use the Spotify API to get the currently signed in user's email and URI
        const currentUserURIAndEmail = await axios.get('https://api.spotify.com/v1/me', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
              }
        }).then((response) => [response.data.uri, response.data.email]);

        // Check if the user's email is in the premiumEmails collection (a Zapier webhook adds emails to this collection when a new customer is added in Stripe)
        const premiumEmailDocRef = doc(db, "premiumEmails", currentUserURIAndEmail[1]);
        const premiumEmailDocSnap = await getDoc(premiumEmailDocRef);

        // If the user is a premium user, update their hostURIs document to reflect that they are a premium user
        if (premiumEmailDocSnap.exists()) { 
          const hostDocRef = doc(db, "hostURIs", currentUserURIAndEmail[0]);
          const docSnap = await getDoc(hostDocRef);
          if (docSnap.exists()) {
            await updateDoc(hostDocRef, {isPremium: true, email: currentUserURIAndEmail[1]});
          } else {
            await setDoc(hostDocRef, {isPremium: true, email: currentUserURIAndEmail[1], numQueuesCreated: 0});
          }
          // Redirect customer home with toast message
          navigate({pathname: '/', search: "?from=payment-successful"});
        } else {
          message.error("It doesn't look like your subscription went through. Please refresh the page to try again or click the \"Contact Support\" button if you believe this is a mistake.", 6);
        }
    }

    getUserDataAndContinue();
  });

  const redirectSpotifyAuth = () => {
    const CLIENT_ID = "2d5be66eceda4176b4c262d815d6c671";
    const REDIRECT_URL = "https://auxify.io/payment-successful"
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
    const RESPONSE_TYPE = "token";
    const SCOPE = "user-modify-playback-state user-read-playback-state user-read-private user-read-email";

    window.location.href = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}`;
  };

  return (
    <FadeIn delay={75}>
      <div style={{ maxWidth: "800px", margin: "0 auto", padding: "1em" }}>
          <Typography.Title style={{ fontSize: "3em" }}>Payment Successful 🥳</Typography.Title>
          <Typography.Paragraph style={{ fontSize: "1em" }}>Please wait to be redirected...</Typography.Paragraph>
          <a href="https://form.typeform.com/to/A5OTFrNo" target="_blank" rel="noreferrer"><Button type="default" size="large" style={{ marginRight: "1em", marginBottom: "1em", marginTop: "1em" }}>Contact Support</Button></a>
      </div>
    </FadeIn>
  )
}

export default PaymentSuccessful