import { Link, useSearchParams } from "react-router-dom";
import FadeIn from 'react-fade-in';
import { Typography, Button, Divider } from 'antd';
import { useEffect } from "react";
import { message } from 'antd';
import ReactGA from "react-ga4";

// Home page with options to either create a new shared queue or join an existing one
function Home() {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.title = "Auxify | Create Shared Spotify Queues";
    // if (searchParams.get("from") === "payment-successful") {
    //   message.success("Successfully upgraded to premium!", 3);
    // }
    if (searchParams.get("from") === "end-queue-successful") {
      message.success("Successfully ended queue", 3);
    }
  });

  return (
    <FadeIn delay={75}>
      <div style={{ maxWidth: "800px", margin: "0 auto", padding: "1em" }}>
          <Typography.Title style={{ fontSize: "3em" }}>Auxify</Typography.Title>
          <Typography.Title style={{ fontSize: "1.5em" }}>Create Shared Spotify Queues 🎧 👯</Typography.Title>
          <Typography.Paragraph style={{ fontSize: "1em" }}>Going on a drive? Hosting a party or kickback? Generate a link that lets your friends contribute to your Spotify queue by adding or skipping songs.</Typography.Paragraph>
          <Link to="/create-queue" onClick={ ReactGA.event("create_queue_button_click_from_home") }><Button type="primary" size="large" style={{ marginRight: "1em", marginBottom: "1em" }}>Create a New Shared Queue</Button></Link>
          <Link to="/join-queue"><Button type="default" size="large" style={{ marginRight: "1em", marginBottom: "1em" }}>Join an Existing Queue</Button></Link>
          {/* <a href="https://buy.stripe.com/3cs9DT6F66cu0LeaEF" onClick={ ReactGA.event("subscribe_button_click_from_home") }><Button type="default" size="large" style={{ marginRight: "1em", marginBottom: "1em" }}>Subscribe to Auxify</Button></a> */}
          <br />
          <br />
          <Divider />
          <br />
          <Typography.Title style={{ fontSize: "1.5em" }}>FAQ</Typography.Title>
          <Typography.Paragraph strong style={{ fontSize: "1em" }}>Q: How does it work?</Typography.Paragraph>
          <Typography.Paragraph>A: Auxify comes in handy when several people listen to music from the same source (e.g. in a car, from a speaker, etc.). Once the person controlling the music creates a shared queue on Auxify (by clicking the "Create a New Shared Queue" button above), everyone else can add or skip songs through the link, QR code, or queue code.</Typography.Paragraph>
          <Typography.Paragraph strong style={{ fontSize: "1em" }}>Q: Is it free?</Typography.Paragraph>
          <Typography.Paragraph>A: Auxify is completely free! Note that hosts must have Spotify premium to create shared queues.</Typography.Paragraph>
          <Typography.Paragraph strong style={{ fontSize: "1em" }}>Q: How is Auxify different from Spotify Group Sessions?</Typography.Paragraph>
          <Typography.Paragraph>A: Auxify is a more polished and accessible version of Group Sessions! Fewer bugs and easier access mean you can start your collaborative listening experience in seconds. Also, unlike Group Sessions, Auxify is accessible from a link, QR code, or queue code to anyone, regardless of whether they're a Spotify user.</Typography.Paragraph>
          <Typography.Paragraph strong style={{ fontSize: "1em" }}>Q: What does it mean to "refresh Spotify access"?</Typography.Paragraph>
          <Typography.Paragraph>A: The token provided by Spotify that lets Auxify control your queue expires after 1 hour. If you want to continue using your shared queue for longer than an hour, hit the refresh button to generate a new token for Auxify.</Typography.Paragraph>
          <a href="https://form.typeform.com/to/A5OTFrNo" target="_blank" rel="noreferrer"><Button type="default" size="large" style={{ marginRight: "1em", marginBottom: "1em", marginTop: "1em" }}>Contact Support</Button></a>
      </div>
    </FadeIn>
  )
}

export default Home