import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import Navigation from './Navigation';
import { ConfigProvider, theme } from 'antd';

// PROJECT ROOT
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm
      }}>
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </ConfigProvider>
  // </React.StrictMode>
);