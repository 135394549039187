import FadeIn from 'react-fade-in';
import { Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from "react-ga4";

// A page to get users to upgrade to the premium plan when they've exhausted the free queue limit
function Home() {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

  useEffect(() => {
    document.title = "Upgrade | Auxify";
  });

  return (
    <FadeIn delay={75}>
      <div style={{ maxWidth: "800px", margin: "0 auto", padding: "1em" }}>
          <Typography.Title style={{ fontSize: "3em" }}>🎧 Subscribe to Auxify</Typography.Title>
          <Typography.Paragraph style={{ fontSize: "1em" }}>You've created 10 free queues with this Spotify account. To continue using Auxify, subscribe for only $1.49/month.</Typography.Paragraph>
          <a href="https://buy.stripe.com/3cs9DT6F66cu0LeaEF" onClick={ ReactGA.event("subscribe_button_click_from_subscribe_page") }><Button block type="primary" size="large">Subscribe</Button></a>
          <br />
          <br />
          <Link to={"/"} onClick={ ReactGA.event("cancel_button_click_on_subscribe_page") }><Button block type='default' size='large'>Cancel</Button></Link>
      </div>
    </FadeIn>
  )
}

export default Home