import { Routes, Route } from "react-router-dom";
import CreateQueue from "./screens/CreateQueue";
import ViewQueue from "./screens/ViewQueue";
import QueueCreated from "./screens/QueueCreated";
import Home from "./screens/Home";
import JoinQueue from "./screens/JoinQueue";
import QCode from "./screens/QCode";
import Subscribe from "./screens/Subscribe";
import PaymentSuccessful from "./screens/PaymentSuccessful";
import ReactGA from "react-ga4";

// Navigation using React Router
function Navigation() {
  ReactGA.initialize("G-JW6KJ4XS38");

  return (
    <>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/join-queue" element={<JoinQueue />} />
          <Route path="/view-queue" element={<ViewQueue />} />
          <Route path="/create-queue" element={<CreateQueue />} />
          <Route path="/queue-created" element={<QueueCreated />} />
          <Route path="/qr-code" element={<QCode />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/payment-successful" element={<PaymentSuccessful />} />
          <Route path="*" element={<Home />} />
      </Routes>
    </>
  )
}

export default Navigation