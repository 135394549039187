import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FadeIn from 'react-fade-in';
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { Typography, Form, Input, Button, message } from 'antd';
import ReactGA from "react-ga4";

function JoinQueue() {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

  // React Router hook to navigate to other pages
  let navigate = useNavigate();
  // Get the queue code from the URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  const [queueCode, setQueueCode] = useState(urlParams.get('queueCode') || "");

  // Function called when the "Join Queue" button is clicked
  const join = async (e) => {
    // Format the queue code to remove whitespace and make it uppercase
    const formattedQueueCode = queueCode.toUpperCase().replace(/\s+/g, '');
    // Get the queue document from Firestore
    const queueDocRef = doc(db, "queues", formattedQueueCode);
    const queueDocSnap = await getDoc(queueDocRef);

    if (queueDocSnap.exists()) {
      // Navigate to the ViewQueue page
      navigate(`/view-queue?queueCode=${formattedQueueCode}`);
      // Clear the queue code
      setQueueCode("");
    } else {
      // Show an error message
      message.error(`Queue ${formattedQueueCode} doesn't exist`, 2.5);
    }
  };

  useEffect(() => {
    document.title = "Join Queue | Auxify";
  });

  return (
    <FadeIn delay={75}>
      <div style={{ maxWidth: "800px", margin: "0 auto", padding: "1em" }}>
        <Typography.Title style={{ fontSize: "3em" }}><Link to="/" style={{ textDecoration: 'none' }}>←</Link> Join a Queue</Typography.Title>
        <Form onFinish={join}>
          <Form.Item htmlFor="queueCode">
            <Input data-hj-allow allowClear size="large" autoFocus type="text" placeholder="Queue Code" onChange={(e) => setQueueCode(e.target.value)} value={queueCode} required />
          </Form.Item>
          <Button type="primary" block size="large" htmlType="submit">Join Queue</Button>
        </Form>
      </div>
    </FadeIn>
  )
}

export default JoinQueue