import Song from '../components/Song';
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import { collection, doc, onSnapshot, setDoc, updateDoc, increment, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faQrcode } from '@fortawesome/free-solid-svg-icons'
import FadeIn from 'react-fade-in';
import { Typography, Button, Divider, Form, Input, List, message, Spin, Avatar, Image } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ReactGA from "react-ga4";

function ViewQueue() {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

  // Custom loading spinner
  const loader = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  // Reads the parameters of the URL
  const [searchParams] = useSearchParams();
  // Gets the current URL in the browser
  const url = window.location.href;

  // Uses RegEx to get the Spotify access token from the current URL
  let accessToken = url.match('(?:access_token=)(.+)(?:&token_type)');
  if (accessToken) {
    accessToken = accessToken[1];
  }
  // Uses RegEx to get the queue code from the current URL (was passed in as state to the auth endpoint) - used if coming from Spotify login after refreshing access
  let urlContainsEnd = url.match('_END_QUEUE');
  let queueCodeSpotify = url.match('(?:state=)(.+)(?:_END_QUEUE)') || url.match('(?:state=)(.+)');
  
  if (queueCodeSpotify) {
    queueCodeSpotify = queueCodeSpotify[1];
  }

  // Gets the queue code from the URL, if coming from the normal page route
  let queueCode = searchParams.get('queueCode');

  // React Router hook to navigate to different pages
  const navigate = useNavigate();

  // Title of the queue
  const [title, setTitle] = useState(<Spin indicator={loader}></Spin>);
  // Description of the queue
  const [description, setDescription] = useState("");
  // Number of people who have voted to skip the current song
  const [skipVotes, setSkipVotes] = useState(0);
  // An array of <Song /> components representing the requested songs
  const [requestedSongs, setRequestedSongs] = useState([]);
  // The Spotify search term entered by a user
  const [trackNameToSearch, setTrackNameToSearch] = useState("");
  // The variable controlling the visibility of the "Vote to Skip" button (button is hidden after voting once)
  const [isSkipVoteButtonVisible, setIsSkipVoteButtonVisible] = useState("inline");
  // The array of songs returned after searching
  const [searchResults, setSearchResults] = useState([]);
  // The number of votes required for a song to be added to the queue
  const [minNumVotes, setMinNumVotes] = useState(0);
  // The number of votes required for a song to be skipped
  const [minVotesToSkip, setMinVotesToSkip] = useState(0);
  // The songs in the current queue
  const [currentQueue, setCurrentQueue] = useState([]);
  // The currently playing song
  const [currentlyPlaying, setCurrentlyPlaying] = useState();
  // The variable tracking if the host is a premium user
  // const [isHostPremium, setIsHostPremium] = useState(false);
  // The interval between each call to getAndShowCurrentQueue()
  const intervalTime = 1000;

  useEffect(() => {
    // Ends the queue if the URL contains an access token and the string "_END_QUEUE"
    const deleteQueueDoc = async () => {
      await deleteDoc(doc(db, "queues", queueCodeSpotify));
    }

    // The access token will exist in the URL if the host refreshed their Spotify login or requested to delete the queue
    const getAccessTokenIfExists = async () => {
      // Remove the access token from the URL (for security)
      navigate(`/view-queue?queueCode=${queueCodeSpotify}`);
      // Reference to the queue document in Firestore
      const queueRef = doc(db, "queues", queueCodeSpotify);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      queueCode = queueCodeSpotify;
      // Get the URI of the host of the queue from Firestore
      const queueHostURI = await getDoc(queueRef).then((doc) => doc.data().host);
      // Get the URI of the user that just signed in
      const currentUserURI = await axios.get('https://api.spotify.com/v1/me', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }).then((response) => response.data.uri);
 
      // Make sure the user that just signed in is the host of the queue
      if (queueHostURI === currentUserURI) {
        if (urlContainsEnd) {
          // If the URL contains "_END_QUEUE", delete the queue document
          await deleteQueueDoc();
          navigate({pathname: '/', search: "?from=end-queue-successful"});
        } else {
          // If the URL does not contain "_END_QUEUE", update the access token in Firestore (the host refreshed their Spotify login)
          await updateDoc(queueRef, {accessToken: accessToken});
        }
      } else {
        message.error("Only the host of this queue can access host controls", 2.5);
      }
    }

    if (accessToken != null) {
      getAccessTokenIfExists();
    }

    // Reference to the queue document in Firestore
    const queueRef = doc(db, "queues", queueCode);
    // Reference to the sub-collection of songs requested by users
    const requestedSongsColRef = collection(db, "queues", queueCode, "requestedSongs");

    // Real-time updates of the queue's title, description, and number of votes to skip the current song from Firestore
    onSnapshot(queueRef, (snapshot) => {
      setTitle(snapshot.data().title);
      // Sets the title of the HTML page to the queue title
      document.title = snapshot.data().title + " | Auxify";
      setDescription(snapshot.data().description);
      setSkipVotes(snapshot.data().skipVotes);
    });

    // Real-time updates of the requested songs from Firestore
    onSnapshot(requestedSongsColRef, (snapshot) => {
      setRequestedSongs(snapshot.docs.map((doc) => doc.data()));
    });

    // Sets minNumVotes from above
    const getMinNumVotes = async () => {
      const queueDocRef = doc(db, "queues", queueCode);
      const queueDocSnap = await getDoc(queueDocRef);
      setMinNumVotes(queueDocSnap.data().minNumVotes);
    }

    // Sets minVotesToSkip from above
    const getMinNumSkipVotes = async () => {
      const queueDocRef = doc(db, "queues", queueCode);
      const queueDocSnap = await getDoc(queueDocRef);
      setMinVotesToSkip(queueDocSnap.data().minNumSkipVotes);
    }

    // const getHostPremiumStatus = async () => {
    //   const queueDocRef = doc(db, "queues", queueCode);
    //   const queueDocSnap = await getDoc(queueDocRef);
    //   let hostURI = queueDocSnap.data().host;

    //   const hostDocRef = doc(db, "hostURIs", hostURI);
    //   const hostDocSnap = await getDoc(hostDocRef);
    //   setIsHostPremium(hostDocSnap.data().isPremium);
    // }

    // getHostPremiumStatus();
    getMinNumVotes();
    getMinNumSkipVotes();

    // Gets the current queue
    getAndShowCurrentQueue();

    // Gets the current queue every intervalTime milliseconds
    const interval = setInterval(() => {
      getAndShowCurrentQueue();
    }, intervalTime);

    return () => clearInterval(interval);
  }, [queueCode, accessToken, navigate, queueCodeSpotify]);

  // Function called when the "Request" button is clicked from the search results
  const handleRequestSong = async (song) => {
    // Clear the input
    setTrackNameToSearch("");
    setSearchResults([]);

    let songURIToRequest = song.uri;
    let songIDToRequest = song.id;

    // If the host disabled voting, the song will be added to the queue immediately
    if (minNumVotes === 0) {
      message.info("Requesting song...", 0.5);
      // A reference to the shared queue document in Firestore
      const queueDocRef = doc(db, "queues", queueCode);
      // A snapshot of the shared queue's document in Firestore
      const queueDocSnap = await getDoc(queueDocRef);

      // Get the host's Spotify access token from Firestore for Spotify API calls
      const token = queueDocSnap.data().accessToken;

      // Headers for the Spotify API POST request below
      const config = {
        headers: { 
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      };

      // Body for the Spotify API POST reqest below
      const body = {
        uri: songURIToRequest,
      }

      // A POST request to the Spotify API that adds this song to the host's queue
      axios.post(
        `https://api.spotify.com/v1/me/player/queue?uri=${songURIToRequest}`,
        body,
        config
      ).then(async function (response) {
        // Deletes this song's document from the requestedSongs sub-collection
        message.success("Song added to queue!", 2.5);
      }).catch(function (error) {
        if (error.response) {
          if (error.response.data.error.status === 404) {
            message.error("Host must be playing music already", 2.5);
          } else if (error.response.data.error.status === 401) {
            // isHostPremium ? message.error("Host must refresh Spotify access", 4) : message.error("This queue has expired - subscribe to Auxify to extend it", 4);
            message.error("Host must refresh Spotify access", 4);
          } else {
            message.error(error.response.data.error.message, 2.5)
          }
        }
      });
    } else {
      // A Firestore reference to the current queue's document
      const queueDocRef = doc(db, "queues", queueCode);
      // A snapshot of the current state of the queue document
      const queueDocSnap = await getDoc(queueDocRef);
      // The value of the accessToken field of the current queue (the access token to the queue host's Spotify account)
      const token = queueDocSnap.data().accessToken;
      
      // Headers for the Spotify API GET request below
      const config = {
        headers: { 
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      };

      // Temporary variable to store the title of the song being requested
      let songTitleToRequest = "";

      // A GET request to the Spotify API setting the value of songTitleToRequest to the title of the song being requested
      await axios.get(
        `https://api.spotify.com/v1/tracks/${songIDToRequest}`,
        config
      ).then(async function (response) {
        songTitleToRequest = response.data.name;
        // A reference to a document in the requested songs sub-collection of the current queue
        const docRef = doc(db, "queues", queueCode, "requestedSongs", songURIToRequest);
        // Sets the fields of the document referenced in the previous line
        await setDoc(docRef, {
          votes: 0,
          uri: songURIToRequest,
          title: songTitleToRequest,
        });
      }).catch(function (error) {
        if (error.response) {
          if (error.response.data.error.status === 401) {
            // isHostPremium ? message.error("Host must refresh Spotify access", 4) : message.error("This queue has expired - upgrade to premium to extend it", 4);
            message.error("Host must refresh Spotify access", 4)
          } else {
            message.error(error.response.data.error.message, 2.5)
          }
        }
      });
    }
  };

    // Function called when searching for a song
    const handleSearchSong = async (trackName) => {
      if (trackName === "") {
        setSearchResults([]);
      } else {
        // Get the access token from Firestore for Spotify API calls
        const queueDocRef = doc(db, "queues", queueCode);
        const queueDocSnap = await getDoc(queueDocRef);
        const token = queueDocSnap.data().accessToken;
    
        // Headers for the Spotify API GET request below
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        };
    
        // A GET request to the Spotify API searching for the track
        await axios.get(
          `https://api.spotify.com/v1/search?q=${trackName}&type=track`,
          config
        ).then(function (response) {
          // Displays the search results
          setSearchResults(response.data.tracks.items);
          // TOOD: loading indicator)
        }).catch(function (error) {
          if (error.response.data.error.status === 401) {
            // isHostPremium ? message.error("Host must refresh Spotify access", 4) : message.error("This queue has expired - upgrade to premium to extend it", 4);
            message.error("Host must refresh Spotify access", 4);
          } else {
            message.error(error.response.data.error.message, 2.5)
          }
        });
      }
    }

  // Function called when the "Skip" button is clicked
  const handleSkipVote = async () => {
    // Pull the minimum number of votes to skip from Firestore
    const queueDocRef = doc(db, "queues", queueCode);
    const queueDocSnap = await getDoc(queueDocRef);
    const numSkipVotes = queueDocSnap.data().skipVotes;
    const token = queueDocSnap.data().accessToken;
    
    // If the host disabled voting, skip immediately
    if (minVotesToSkip === 0) {
      message.info("Skipping song...", 0.5);
      const config = {
        headers: { 
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      };

      const body = {
        uri: numSkipVotes,
      }

      axios.post(
        'https://api.spotify.com/v1/me/player/next',
        body,
        config
      ).then(async function () {
        message.success("Skipped to the next song!", 2.5);
      }).catch(function (error) {
        if (error.response) {
          if (error.response.data.error.status === 404) {
            message.error("Host must be playing music already", 2.5);
          } else if (error.response.data.error.status === 401) {
            // isHostPremium ? message.error("Host must refresh Spotify access", 4) : message.error("This queue has expired - upgrade to premium to extend it", 4);
            message.error("Host must refresh Spotify access", 4);
          } else {
            message.error(error.response.data.error.message, 2.5)
          }
        }
      });
    } else {
      // Hide the skip button
      setIsSkipVoteButtonVisible("none");

      // If the number of skip votes has reached the minimum to skip
      if (numSkipVotes + 1 >= queueDocSnap.data().minNumSkipVotes) {
        const config = {
          headers: { 
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        };

        const body = {
          uri: numSkipVotes,
        }

        axios.post(
          'https://api.spotify.com/v1/me/player/next',
          body,
          config
        ).then(async function () {
          // Skip the song and reset the skip votes
          await updateDoc(queueDocRef, {skipVotes: 0});
          // Show the skip button again
          setIsSkipVoteButtonVisible("inline");
          message.success("Vote to skip song passed!", 2.5);
        }).catch(function (error) {
          setIsSkipVoteButtonVisible("inline");
          if (error.response) {
            if (error.response.data.error.status === 404) {
              message.error("Host must be playing music already", 2.5);
            } else if (error.response.data.error.status === 401) {
              // isHostPremium ? message.error("Host must refresh Spotify access", 4) : message.error("This queue has expired - upgrade to premium to extend it", 4);
              message.error("Host must refresh Spotify access", 4);
            } else {
              message.error(error.response.data.error.message, 2.5)
            }
          }
        });
      } else {
        // The number of skip votes has not reached the minimum to skip - add a vote to skipVotes
        await updateDoc(queueDocRef, {skipVotes: increment(1)});
      }
    }
  }

  // Function called when the "Refresh Spotify Access" button is clicked
  const refreshSpotifyAuth = async (e) => {
    // Prevents the page from refreshing
    e.preventDefault();
    const CLIENT_ID = "2d5be66eceda4176b4c262d815d6c671";
    const REDIRECT_URL = "https://auxify.io/view-queue"
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
    const RESPONSE_TYPE = "token";
    const SPOTIFY_STATE = queueCode;
    const SCOPE = "user-modify-playback-state user-read-playback-state";

    const url = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=${RESPONSE_TYPE}&state=${SPOTIFY_STATE}&scope=${SCOPE}`;
    window.location.href = url;
  }

  // The function called whenever the queue is updated
  const getAndShowCurrentQueue = async () => {
    const queueDocRef = doc(db, "queues", queueCode);
    const queueDocSnap = await getDoc(queueDocRef);
    const token = queueDocSnap.data().accessToken;

    // Headers for the Spotify API GET request below
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };

    // A GET request to the Spotify API getting the current queue
    await axios.get(
      `https://api.spotify.com/v1/me/player/queue`,
      config
    ).then(function (response) {
      setCurrentQueue(response.data.queue);
      setCurrentlyPlaying(response.data.currently_playing);
    }).catch(function (error) {
      if (error.response.data.error.status === 401) {
        // isHostPremium ? message.error("Host must refresh Spotify access", 4) : message.error("This queue has expired - subscribe to Auxify to extend it", 4);
        message.error("Host must refresh Spotify access", 4);
      } else {
        message.error(error.response.data.error.message);
      }
    });
  }

  // Function called when a song is searched for
  const handleSearchSongInput = (e) => {
    setTrackNameToSearch(e.target.value);
    handleSearchSong(e.target.value);
  }

  // Function called when the "End Queue" button is clicked
  const endQueue = (e) => {
    // Prevents the page from refreshing
    e.preventDefault();
    const CLIENT_ID = "2d5be66eceda4176b4c262d815d6c671";
    const REDIRECT_URL = "https://auxify.io/view-queue"
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
    const RESPONSE_TYPE = "token";
    const SPOTIFY_STATE = queueCode + "_END_QUEUE";
    const SCOPE = "user-modify-playback-state user-read-playback-state";

    const url = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=${RESPONSE_TYPE}&state=${SPOTIFY_STATE}&scope=${SCOPE}`;
    window.location.href = url;
  }

  return (
    <FadeIn delay={75}>
      <div style={{ maxWidth: "800px", margin: "0 auto", padding: "1em" }}>
        <Typography.Title style={{ fontSize: "3em" }}><Link to={`/join-queue?queueCode=${queueCode}`} style={{ textDecoration: 'none' }}>←</Link> {title} • {queueCode} &nbsp;<FontAwesomeIcon icon={faLink} size='xs' onClick={() => {navigator.clipboard.writeText(`https://auxify.io/view-queue?queueCode=${queueCode}`); message.info("Copied queue URL to clipboard", 2.5)}} style={{color: '#0076d1'}} cursor={'pointer'} /> &nbsp; <FontAwesomeIcon icon={faQrcode} size='xs' onClick={() => {navigate(`/qr-code?queueCode=${queueCode}`)}} style={{color: '#0076d1'}} cursor={'pointer'} /></Typography.Title>
        <Typography.Paragraph style={{ display: description === "" ? 'none' : 'inline', fontSize: "1em" }} >{description}</Typography.Paragraph>
        <br style={{ display: description === "" ? 'none' : 'inline' }}/>
        <Typography.Title style={{ marginRight: "0.5em" }} level={2}>Current Queue</Typography.Title>
        <div>
          {/* <Button type='primary' style={{ marginBottom: "1em" }} icon={<ReloadOutlined />} onClick={getAndShowCurrentQueue}>Refresh</Button> */}
          {currentlyPlaying ? <Typography.Paragraph style={{ fontSize: "1em" }}>Currently playing: {currentlyPlaying.name} by {currentlyPlaying.artists[0].name}</Typography.Paragraph> : <Typography.Paragraph>The host is either not playing music or must refresh Spotify access</Typography.Paragraph>}
          <Typography.Paragraph style={{ display: currentlyPlaying ? "inline" : "none", fontSize: "1em" }}>Up next:</Typography.Paragraph>
          <List style={{ maxHeight: "190px", overflow: "scroll" }}>
            {currentQueue.length > 0 ? currentQueue.map(song => {
              return (
                <List.Item style={{ fontSize: "1.1em" }} key={song.name}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar shape='square' style={{ borderRadius: "0px" }} src={song.album.images[0].url} /> 
                    <div style={{ marginLeft: "1em" }}>{song.name} by {song.artists[0].name}</div>
                  </div>
                  <a style={{ marginLeft: "0.5em" }} href={song.external_urls.spotify} target="_blank" rel="noreferrer"><Image src="https://firebasestorage.googleapis.com/v0/b/auxify-c9d88.appspot.com/o/spotifyIcon.png?alt=media&token=797a9b0d-866d-45b2-8f18-f3ab941a8088" preview={false} width={25} /></a>
                </List.Item>
              )
            }) : <></>}
          </List>
        </div>
        <Divider />
        <Typography.Title level={2}>Add Songs</Typography.Title>
        <Typography.Paragraph style={{ display: minNumVotes === 0 ? 'none' : 'inline' }}>Number of votes needed to add a song to the queue: {minNumVotes}</Typography.Paragraph>
        <br style={{ display: minNumVotes === 0 ? 'none' : 'inline' }} />
        <br style={{ display: minNumVotes === 0 ? 'none' : 'inline' }} />
        <Form.Item htmlFor="title" style={{ flex: 7, marginRight: "1em" }}>
          <Input data-hj-allow allowClear size="large" type="text" value={trackNameToSearch} onChange={(e) => handleSearchSongInput(e)} placeholder="Enter a song title" />
        </Form.Item>
        <div style={{ zIndex: 1, position: 'absolute', backgroundColor: "rgb(10, 30, 65)", marginRight: "2em", height: "20em", maxWidth: "30em", overflow: searchResults.length > 0 ? "scroll" : "hidden", marginTop: "-1em", borderRadius: "10px", boxShadow: "0 0 7px rgba(255, 255, 255, 0.3)" }}>
          {searchResults.map(song => {
            return(
              <div style={{display: "flex", alignItems: "center", padding: "1em"}}>
                <Avatar shape='square' src={song.album.images[0].url} style={{ marginRight: "1em", borderRadius: "0px" }} /> 
                <Typography.Paragraph style={{ flex: 8, marginRight: "1em" }}>{song.name} - {song.artists[0].name} {song.explicit ? "(Explicit)" : null}</Typography.Paragraph>
                <Button style={{ flex: 1, marginRight: "1em" }} onClick={() => handleRequestSong(song)}>Request</Button>
                <a href={song.external_urls.spotify} target="_blank" rel="noreferrer"><Image src="https://firebasestorage.googleapis.com/v0/b/auxify-c9d88.appspot.com/o/spotifyIcon.png?alt=media&token=797a9b0d-866d-45b2-8f18-f3ab941a8088" preview={false} width={25} /></a>
              </div>
            );
          })}
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, 240.01px)", gap: "1em", justifyContent: "center" }}>
          {requestedSongs.sort((songA, songB) => (songA.votes < songB.votes) ? 1 : -1).map(song => { 
            return(
              <Song key={song.uri} songURI={song.uri} title={song.title} votes={song.votes} queueCode={queueCode} />
            );
          })}
        </div>
        <br style={{ display: requestedSongs.length > 0 ? "block" : "none" }} />
        <br style={{ display: requestedSongs.length > 0 ? "block" : "none" }} />
        <Divider />
        <Typography.Title level={2}>Skip Songs</Typography.Title>
        <Typography.Paragraph style={{ display: minVotesToSkip === 0 ? "none" : "inline" }}>Number of votes needed to skip a song: {minVotesToSkip}</Typography.Paragraph>
        <br style={{ display: minVotesToSkip === 0 ? "none" : "inline" }} />
        <Typography.Paragraph style={{ display: minVotesToSkip === 0 ? "none" : "inline" }}>Votes: {skipVotes}</Typography.Paragraph>
        <br style={{ display: minVotesToSkip === 0 ? "none" : "inline" }} />
        <br style={{ display: minVotesToSkip === 0 ? "none" : "inline" }} />
        <Button type='primary' block size='large' style={{ display: isSkipVoteButtonVisible }} onClick={handleSkipVote}>{minVotesToSkip === 0 ? "Skip Current Song" : "Vote to Skip Current Song"}</Button>
        <br />
        <br />
        <Divider />
        <br />
        <Typography.Paragraph strong>Host Controls</Typography.Paragraph>
        {/* {
          isHostPremium ? 
            <>
              <Button onClick={refreshSpotifyAuth} style={{ marginRight: "1em", marginBottom: "1em" }}>Refresh Spotify Access</Button>
              <Button onClick={endQueue} danger type='dashed'>End Queue</Button>
            </> : 
            <>
              <Typography.Paragraph>Subscribe to Auxify to refresh your Spotify access and extend this queue for only $1.49/month</Typography.Paragraph> 
              <a href="https://buy.stripe.com/3cs9DT6F66cu0LeaEF" style={{ marginRight: "1em", marginBottom: "1em" }}><Button>Subscribe to Auxify</Button></a>
              <Button onClick={endQueue} danger type='dashed'>End Queue</Button>
            </>
        } */}
        <>
          <Button onClick={refreshSpotifyAuth} style={{ marginRight: "1em", marginBottom: "1em" }}>Refresh Spotify Access</Button>
          <Button onClick={endQueue} danger type='dashed'>End Queue</Button>
        </>
        <br />
        <br />
        <Divider />
        <br />
        <div style={{ display: 'flex', alignItems: "baseline"}}>
          <Typography.Paragraph>Album artworks provided by &nbsp;&nbsp;</Typography.Paragraph>
          <Image src="https://firebasestorage.googleapis.com/v0/b/auxify-c9d88.appspot.com/o/spotifyLogo.png?alt=media&token=bcba9caa-6cc2-47fc-aca4-ea936761f00e" preview={false} width={100} />
        </div>
        <br />
        <br />
      </div>
    </FadeIn>
  );
}

export default ViewQueue;