import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

// Firebase config
const firebaseConfig = {
    apiKey: process.env.FIREBASE_CONFIG_API_KEY,
    authDomain: "auxify-c9d88.firebaseapp.com",
    projectId: "auxify-c9d88",
    storageBucket: "auxify-c9d88.appspot.com",
    messagingSenderId: process.env.FIREBASE_CONFIG_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_CONFIG_APP_ID,
    measurementId: process.env.FIREBASE_CONFIG_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);