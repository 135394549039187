import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { deleteDoc, doc, getDoc, increment, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useState } from 'react';
import axios from 'axios';
import FadeIn from 'react-fade-in';
import { message } from 'antd';

function Song({title, votes, queueCode, songURI}) {
  // A variable controlling the visibility of the upvote icon (disappears after a user votes once)
  const [isUpvoteVisible, setIsUpvoteVisible] = useState("inline");

  // Function called when a user upvotes a requested song
  const handleUpvote = async () => {
    // TODO: use cookies to prevent users from voting multiple times

    // Hides the upvote button so users can't vote for a single song multiple times
    setIsUpvoteVisible("none");

    // A reference to the song document in the Firestore requestedSongs sub-collection
    const songDocRef = doc(db, "queues", queueCode, "requestedSongs", songURI);
    // A snapshot of the current state of this song's document in Firestore
    const songDocSnap = await getDoc(songDocRef);
    // The number of votes this song has
    const numVotes = songDocSnap.data().votes;

    // A reference to the shared queue document in Firestore
    const queueDocRef = doc(db, "queues", queueCode);
    // A snapshot of the current state of the shared queue's document in Firestore
    const queueDocSnap = await getDoc(queueDocRef);

    // When this song hits the number of votes required to be added to the queue...
    if (numVotes + 1 >= queueDocSnap.data().minNumVotes) {
      // Get the song's URI and title from Firestore
      const songURI = songDocSnap.data().uri;
      const songTitle = songDocSnap.data().title;
      // Get the host's Spotify access token from Firestore for Spotify API calls
      const token = queueDocSnap.data().accessToken;

      // Headers for the Spotify API POST request below
      const config = {
        headers: { 
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      };

      // Body for the Spotify API POST reqest below
      const body = {
        uri: songURI,
      }

      // A POST request to the Spotify API that adds this song to the host's queue
      axios.post(
        `https://api.spotify.com/v1/me/player/queue?uri=${songURI}`,
        body,
        config
      ).then(async function (response) {
        // Deletes this song's document from the requestedSongs sub-collection because it's been added to the queue
        await deleteDoc(songDocRef);
        message.success(`${songTitle} added to queue`, 2.5);
      }).catch(function (error) {
        // There was an error adding the song to the host's queue, so the upvote button is shown again
        setIsUpvoteVisible("inline");
        if (error.response) {
          if (error.response.data.error.status === 404) {
            message.error("Host must be playing music already", 2.5);
          } else if (error.response.data.error.status === 401) {
            message.error("Host's Spotify access has expired", 4);
          } else {
            message.error(error.response.data.error.message, 2.5)
          }
        }
      });
    } else {
      // Increases the number of votes for this song in Firestore by 1
      await updateDoc(songDocRef, {votes: increment(1)});
    }
  }

  // Display the song title, number of votes, and the upvote icon
  return (
    <FadeIn delay={75}>
      <div style={{ border: "1.5px solid rgb(0, 0, 0)", borderRadius: "10px", backgroundColor: "rgb(219, 247, 181)", color: "black", boxShadow: "0 0 5px rgba(255, 255, 255, 0.3)", padding: "1em", flexDirection: "column", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
        <h6 style={{ color: "black" }}>{title}</h6>
        <p style={{ color: "black" }} >Votes: {votes}</p>
        <button style={{display: isUpvoteVisible}} onClick={handleUpvote}><FontAwesomeIcon icon={faArrowUp} size="lg" /></button>
      </div>
    </FadeIn>
  );
}

// Default props for this song if none are passed in
Song.defaultProps = {
  title: "Song",
  votes: 0,
  queueCode: "",
  songURI: 0
}

export default Song