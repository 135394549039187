import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import FadeIn from 'react-fade-in';
import { Typography } from 'antd';
import ReactGA from "react-ga4";

function QCode() {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

  // Get the queue code from the URL
  const queueCode = new URLSearchParams(window.location.search).get("queueCode");

  return (
    <FadeIn delay={75}>
      <div style={{ maxWidth: "800px", margin: "0 auto", padding: "1em"}}>
        <Typography.Title style={{ fontSize: "3em" }}><Link to={`/view-queue?queueCode=${queueCode}`} style={{ textDecoration: 'none' }}>←</Link> QR Code</Typography.Title>
        <Typography.Paragraph style={{ fontSize: "1em" }}>Share this QR code to give your friends access to the shared queue!</Typography.Paragraph>
        <QRCode value={`https://auxify.io/view-queue?queueCode=${queueCode}`} size={300} />
      </div>
    </FadeIn>
  );
}

export default QCode