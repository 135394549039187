import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faLink } from '@fortawesome/free-solid-svg-icons'
import { db } from "../firebase";
import { doc, increment, getDoc, updateDoc, setDoc } from "firebase/firestore";
import QRCode from "react-qr-code";
import { useEffect } from 'react';
import axios from 'axios';
import FadeIn from 'react-fade-in';
import { Typography, Button, message } from 'antd';
import ReactGA from "react-ga4";

function QueueCreated() {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    
  // Get the queueCode from the URL using search params (won't be null if user was already on the QueueCreated page, they're just coming back because of the functionality cleaning the access code from the URL)
  const urlParams = new URLSearchParams(window.location.search);
  let queueCode = urlParams.get('queueCode');

  // If the user came from the Spotify OAuth endpoint
  let accessToken;
  if (!queueCode) {
    // Gets the current URL in the browser
    const url = window.location.href;
    // Uses RegEx to get the queue code from the current URL (was passed in as state in SpotifyAuth.js to the auth endpoint)
    queueCode = url.match('(?:state=)(.+)')[1];
    // Uses RegEx to get the Spotify access token from the current URL
    accessToken = url.match('(?:access_token=)(.+)(?:&token_type)')[1];
  }

  useEffect(() => {
    document.title = "Queue Created | Auxify";
  });
  
  // Creates a Firestore field in the queue's document to hold the access token to the queue host's Spotify account, as well as the host's Spotify URI
  const getUserURIAndSetQueueHost = async () => {
    // Gets the current user's Spotify URI using the Spotify API
    axios.get(`https://api.spotify.com/v1/me`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then(async (response) => {
      let userURI = response.data.uri;
      updateDoc(doc(db, "queues", queueCode), {
        host: userURI,
        accessToken: accessToken
      });
      
      // Increments the number of queues created by the user in Firestore
      const docRef = doc(db, "hostURIs", userURI);
      const docSnap = await getDoc(docRef);
      
      // if (docSnap.exists() && !docSnap.data().isPremium) {
      //   // If the number of queues created by the user in Firestore is >= the limit, delete the queue document redirect them to the subscribe screen
      //   if (docSnap.data().numQueuesCreated >= 10) {
      //     deleteDoc(doc(db, "queues", queueCode));
      //     navigate('/subscribe');
      //     return;
      //   }
      
      //   updateDoc(docRef, {
      //     numQueuesCreated: increment(1)
      //   });
      // } else if (docSnap.exists() && docSnap.data().isPremium) {
      //   updateDoc(docRef, {
      //     numQueuesCreated: increment(1)
      //   });
      //   return;
      // } else {
      //   setDoc(docRef, {
      //     numQueuesCreated: 1,
      //     isPremium: false
      //   });
      // }

      if (docSnap.exists()) {
        updateDoc(docRef, {
          numQueuesCreated: increment(1)
        });
      } else {
        setDoc(docRef, {
          numQueuesCreated: 1,
        });
      }
    })
  };

  // React Router hook to navigate to a new page
  const navigate = useNavigate()

  useEffect(() => {
    // If the user came from the Spotify OAuth endpoint, clean the URL of the accessToken
    if (accessToken) {
      // Remove the access_token paramter from the URL for security resasons
      navigate(`/queue-created?queueCode=${queueCode}`);
    }
    // Get the user's Spotify URI and set the queue host in Firestore
    getUserURIAndSetQueueHost();
  });

  return (
    <FadeIn delay={75}>
      <div style={{ maxWidth: "800px", margin: "0 auto", padding: "1em", textAlign: 'center' }}>
        <Typography.Title style={{ fontSize: "3em", textAlign: "left" }}>Queue Created!</Typography.Title>
        <Typography.Title level={3} style={{ color: "green", textAlign: "left" }}>Queue code: {queueCode} &nbsp; <FontAwesomeIcon icon={faCopy} size='xs' onClick={() => {navigator.clipboard.writeText(queueCode); message.info("Copied queue code to clipboard", 2.5)}} style={{color: '#0076d1'}} cursor={'pointer'} /> &nbsp; <FontAwesomeIcon icon={faLink} size='xs' onClick={() => {navigator.clipboard.writeText(`https://auxify.io/view-queue?queueCode=${queueCode}`); message.info("Copied queue URL to clipboard", 2.5)}} style={{color: '#0076d1'}} cursor={'pointer'} /></Typography.Title>
        <Typography.Paragraph style={{ fontSize: "1em", textAlign: "left" }}>Share the queue link, QR code, or queue code with your friends!</Typography.Paragraph>
        <Link to={`/view-queue?queueCode=${queueCode}`} style={{ textAlign: "left" }}><Button type="primary" block>Go to Queue</Button></Link>
        <br />
        <br />
        <QRCode value={`https://auxify.io/view-queue?queueCode=${queueCode}`} />
      </div>
    </FadeIn>
  )
}

export default QueueCreated