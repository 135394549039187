import { Link } from "react-router-dom";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import FadeIn from 'react-fade-in';
import { Typography, Form, Input, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ReactGA from "react-ga4";

function CreateQueue() {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

  // Custom loading spinner
  const loader = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  // Current date and time
  var currentDate = new Date();

  // Generate an alphanumeric code based on the current date and time to identify shared queues
  var alphanumericCode = ((currentDate.getDate() + (currentDate.getMonth() + 1) + currentDate.getFullYear() + currentDate.getHours() + currentDate.getMinutes() + currentDate.getSeconds()) +  Math.random().toString(36).substring(2, 3) +  Math.random().toString(36).substring(2, 3)).toUpperCase();

  // Title of the shared queue
  const [title, setTitle] = useState("");
  // Description of the shared queue (optional)
  const [description, setDescription] = useState("");
  // The minimum number of votes a requested song needs to be added to the queue
  const [minNumVotes, setMinNumVotes] = useState(0);
  // The minimum number of votes required to skip the current song
  const [minNumSkipVotes, setMinNumSkipVotes] = useState(0);
  // The alphanumeric queue code generated above
  const [queueCode, setQueueCode] = useState(alphanumericCode);
  // Controls the text of the Create Queue button
  const [buttonText, setButtonText] = useState("Create Queue");

  useEffect(() => {
    document.title = "Create Queue | Auxify";
  });

  // Function called when the "Create Queue" button is clicked at the end of the form
  const createQueue = async () => {
    setButtonText(<Spin indicator={loader} style={{ color: "white" }}></Spin>);

    // Creates a document in Firestore representing the queue
    await setDoc(doc(db, "queues", queueCode), {
        title: title,
        description: description,
        minNumVotes: minNumVotes,
        minNumSkipVotes: minNumSkipVotes,
        queueCode: queueCode,
        skipVotes: 0,
        host: "",
    });

    // Re-generates a different alphanumeric queue code to reset the queue creation form with (to prevent another queue being created with the same code)
    alphanumericCode = ((currentDate.getDate() + (currentDate.getMonth() + 1) + currentDate.getFullYear() + currentDate.getHours() + currentDate.getMinutes() + currentDate.getSeconds()) +  Math.random().toString(36).substring(2, 3) +  Math.random().toString(36).substring(2, 3)).toUpperCase();
  
    // Setting all form fields back to their defaults
    setTitle("");
    setDescription("");
    setMinNumVotes(0);
    setMinNumSkipVotes(0);
    setQueueCode(alphanumericCode);
    
    // Navigate to the Spotify auth page
    const CLIENT_ID = "2d5be66eceda4176b4c262d815d6c671";
    const REDIRECT_URL = "https://auxify.io/queue-created"
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
    const RESPONSE_TYPE = "token";
    const SPOTIFY_STATE = queueCode;
    const SCOPE = "user-modify-playback-state user-read-playback-state user-read-private user-read-email";

    setButtonText("Create Queue");

    window.location.href = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=${RESPONSE_TYPE}&state=${SPOTIFY_STATE}&scope=${SCOPE}`;
  };

  return (
    <FadeIn delay={75}>
      <div style={{ maxWidth: "800px", margin: "0 auto", padding: "1em" }}>
        <Typography.Title style={{ fontSize: "3em" }}><Link to="/" style={{ textDecoration: 'none' }}>←</Link> Create a Shared Queue</Typography.Title>
        <Typography.Paragraph style={{ fontSize: "1em" }}>As the queue host, you must have Spotify premium to use Auxify</Typography.Paragraph>
        <Form onFinish={createQueue}>
          <Form.Item htmlFor="title" label="Queue Title*">
            <Input data-hj-allow size="large" maxLength={20} showCount autoFocus autoCapitalize="words" type="text" placeholder="Give your queue a title" id="title" name="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
          </Form.Item>

          <Form.Item htmlFor="description" label="Queue Description">
            <Input data-hj-allow allowClear size="large" type="text" autoCapitalize="sentences" placeholder="What's the queue for?" id="description" name="description" value={description} onChange={(e) => setDescription(e.target.value)} style={{ resize: "vertical" }} />
          </Form.Item>

          {/* <label htmlFor="minNumVotes">Minimum Number of Votes for a Requested Song to be Added to Queue* (0 to disable voting)</label>
          <input type="number" min={0} id="minNumVotes" name="minNumVotes" value={minNumVotes} onChange={(e) => setMinNumVotes(e.target.value)} required />
          <br />

          <label htmlFor="minNumSkipVotes">Minimum Number of Votes for a the Current Song to be Skipped* (0 to disable voting)</label>
          <input type="number" min={0} id="minNumSkipVotes" name="minNumSkipVotes" value={minNumSkipVotes} onChange={(e) => setMinNumSkipVotes(e.target.value)} required />
          <br /> */}

          <Button block type="primary" size="large" htmlType="submit">{buttonText}</Button>
        </Form>
      </div>
    </FadeIn>
  )
}

export default CreateQueue